const Services = [
    {
        Id: '1',
        sTitle: 'Full-Stack Development',
        description:
            `
            Mastering both front-end elegance and back-end robustness, 
            I specialize in delivering end-to-end web applications. 
            Seamlessly integrating technologies like HTML, CSS, and JavaScript with powerful server-side solutions, 
            I bring a comprehensive approach to enhance your digital presence.
            `,
        icon: 'flaticon-coding',
    },
    {
        Id: '2',
        sTitle: 'Data Science & AI',
        description:
            `
            Experience the forefront of innovation with my specialized AI engineering services. 
            Leveraging advanced machine learning algorithms and frameworks like PyTorch, 
            I architect intelligent systems. From nuanced natural language processing to intricate computer vision applications, 
            I bring a strategic approach to implementing AI solutions that translate data into actionable insights.
            `,
        icon: 'flaticon-software-developer',
    },
    {
        Id: '3',
        sTitle: 'Software Development',
        description:
            `
            Embark on technological excellence with my bespoke software development services. 
            As a seasoned software engineer, I provide tailored solutions addressing unique business challenges. 
            From conceptualization to execution, I ensure scalable and efficient software applications aligned with your organizational goals.
            `,
        icon: 'flaticon-app-development',
    }
]

export default Services;