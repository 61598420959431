import React, {useState, useRef} from 'react'
import SimpleReactValidator from 'simple-react-validator';
import emailjs from 'emailjs-com'

const ContactForm = () => {

    const [result, showResult] = useState(false);

    const Result = () => {
        return (
            <p className="sentConfirmation" >Your message has been sent, I will get back to you soon!</p>
        )
    }

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_rqp96xd', 'template_9w746wj', form.current, 'gvc4oJX3-8RtMhC9C')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        showResult(true);
        setTimeout(() => {
            showResult(false);
        }, 3000);}

    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({...forms, [e.target.name]: e.target.value})
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            sendEmail(e);
            validator.hideMessages();
            setForms({
                name: '',
                email: '',
                message: ''
            })
        } else {
            validator.showMessages();
        }
    };

    return (
        <form className="contact-validation-active" ref={form} onSubmit={submitHandler}>
            <div className="row">
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Name"/>
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Email"/>
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <textarea
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        value={forms.message}
                        type="text"
                        name="message"
                        placeholder="Message">
                    </textarea>
                    {validator.message('message', forms.message, 'required')}
                </div>
            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn">Submit Now</button>
            </div>
            <div className="sentConfirmation">{result ? <Result/> : null}</div>
        </form>
    )
}

export default ContactForm;