import React, {Fragment} from 'react';
import ContactArea from '../../components/ContactArea';
import Experience2 from '../../components/Experience2/Experience2';
import Footer from '../../components/footer/Footer';
import Hero from '../../components/hero/hero';
import Navbar from '../../components/Navbar/Navbar'
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ServiceSection from '../../components/ServiceSection/ServiceSection';

const HomePage = () => {
    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'}/>
            <Hero/>
            <ServiceSection/>
            <Experience2/>
            <ProjectSection/>
            <ContactArea/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    );
};
export default HomePage;