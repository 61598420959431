import React from 'react'
import {Link} from 'react-scroll'
import Logo from '../../images/logo_AY.svg'
const Footer = () => {
    return (
        <footer className="wpo-site-footer">
            <div className="upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-6 col-md-6 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <Link className="site-logo" to="/"><img
                                        src={Logo} alt=""/>Achraf.</Link>
                                </div>
                                <p>Thanks for checking out my page. Feel free to reach out to me.</p>
                                <div className="social-icons">
                                    <ul>
                                        <li><a href="https://github.com/AchrafYndz"><i className="ti-github"></i></a>
                                        </li>
                                        <li><a href="https://www.linkedin.com/in/achraf-yandouzi-b0ab23201/"><i
                                            className="ti-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-4 col-md-6 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Navigation</h3>
                                </div>
                                <div className="row">
                                    <div className="col col-lg-6">
                                        <ul>
                                            <li><Link to="service" spy={true} smooth={true} duration={500}>Service</Link></li>
                                            <li><Link to="experience" spy={true} smooth={true} duration={500}>Experience</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col col-lg-6">
                                        <ul>
                                            <li><Link to="portfolio" spy={true} smooth={true} duration={500}>Portfolio</Link></li>
                                            <li><Link to="contact" spy={true} smooth={true} duration={500}>Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col col-lg-2 col-md-6 col-12">
                            <div className="widget link-widget service-link-widget">
                                <div className="widget-title">
                                    <h3>Socials</h3>
                                </div>
                                <ul>
                                    <li><a href="https://github.com/AchrafYndz">Github</a>
                                    </li>
                                    <li><a href="https://www.linkedin.com/in/achraf-yandouzi-b0ab23201/">LinkedIn</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shadow-shape">
                    <svg width="1319" height="1567" viewBox="0 0 1319 1567" fill="none">
                        <g filter="url(#filter0_f_39_3833)">
                            <circle cx="803" cy="803" r="303" fill="#59C378" fillOpacity="0.5"/>
                        </g>
                        <defs>
                            <filter id="filter0_f_39_3833" x="0" y="0" width="1606" height="1606"
                                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="250" result="effect1_foregroundBlur_39_3832"/>
                            </filter>
                        </defs>
                    </svg>
                </div>
            </div>
            <div className="lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="separator"></div>
                        <p className="copyright">Copyright &copy; {new Date().getFullYear()} Achraf. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;