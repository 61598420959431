import { useEffect, useState } from 'react';
import Slider from "react-slick";

const settings = {
    dots: false,
    arrows: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                dots: true,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                dots: true,
                slidesToScroll: 1
            }
        }
    ]
};

const Projects = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await fetch('https://api.github.com/users/AchrafYndz/repos');
                const data = await response.json();

                const filteredRepos = data.filter(repo => repo.name !== 'AchrafYndz');

                const repos = filteredRepos.map((repo) => ({
                    Id: repo.id.toString(),
                    title: repo.name,
                    subTitle: repo.description || 'No description',
                    link: repo.html_url,
                    branch: repo.default_branch
                }));

                setProjects(repos);
            } catch (error) {
                console.error('Error fetching GitHub repositories:', error.message);
            }
        };

        fetchProjects();
    }, []);

    return (
        <Slider {...settings}>
            {projects.map((project) => (
                <div key={project.Id} className="wpo-project-item">
                    <img
                        className="wpo-project-img"
                        src={project.link + `/blob/${project.branch}/images/logo.png?raw=true`}
                        alt={project.title}
                    />
                    <div>
                        <h3 className="wpo-project-text">{project.title}</h3>
                        <p className="wpo-project-text">{project.subTitle}</p>
                        <a href={project.link} target="_blank" rel="noopener noreferrer">
                            View Project
                        </a>
                    </div>
                </div>
            ))}
        </Slider>
    );
};

export default Projects;

