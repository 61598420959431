import React from 'react'
import logo1 from '../../images/work/1.svg'
import SectionTitle from '../SectionTitle/SectionTitle'

const Experiences = [
    {
        date: '2023-2024',
        logo: logo1,
        position: 'Data Scientist',
        companyName: 'Trackbox',
        workFrom: 'Antwerp, Belgium',
        site: 'https://www.trackbox.be/'
    }
]


const Experience2 = () => {
    return (
        <div className="wpo-work-area-s2 section-padding" id="experience">
            <div className="container">
                <SectionTitle Title={'My Work Experience'} Paragraph={'A list of my relevant jobs.'}/>
                <div className="wpo-work-wrap">
                    <div className="row">
                        {Experiences.map((experience, exp) => (
                            <div className="col-lg-12 col-md-6 col-12" key={exp}>
                                <div className="wpo-work-item">
                                    <ul>
                                        <li className="logo">
                                            <img
                                                src={experience.logo}
                                                alt=""
                                                style={{ height: '70px' }}
                                            />
                                        </li>
                                        <li className="date">{experience.date}</li>
                                        <li className="position">{experience.position} <span>{experience.companyName}
                                            <br/><span>{experience.workFrom}</span> </span></li>
                                        <li className="link"><a href={experience.site}>Take me there</a></li>
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="shape-wk">
                <svg width="1500" height="1500" viewBox="0 0 1500 1500" fill="none">
                    <g opacity="0.45" filter="url(#filter0_f_39_4214)">
                        <circle cx="750" cy="750" r="200"/>
                    </g>
                    <defs>
                        <filter id="filter0_f_39_4214" x="0" y="0" width="1500" height="1500"
                                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                            <feGaussianBlur stdDeviation="275" result="effect1_foregroundBlur_39_4212"/>
                        </filter>
                    </defs>
                </svg>
            </div>
        </div>
    )
}

export default Experience2;