import React from 'react';
import SectionTitle from "../SectionTitle/SectionTitle";
import Services from '../../api/service'

const ServiceSection = (props) => {
    return (
        <div className={`wpo-service-area section-padding ${props.sClass}`} id='service'>
            <div className="container">
                <SectionTitle Title={'My Skills'} Paragraph={'Here are some of the services I offer.'}/>
                <div className="wpo-service-wrap">
                    <div className="row">
                        {Services.slice(0, 3).map((service, srv) => (
                            <div className="col-lg-4 col-md-6 col-12" key={srv}>
                                <div className="wpo-service-item">
                                    <div className="icon">
                                        <i className={`fi ${service.icon}`}></i>
                                    </div>
                                    <h2>{service.sTitle}</h2>
                                    <p>{service.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="ab-shape">
                <svg width="995" height="1495" viewBox="0 0 995 1495" fill="none">
                    <g opacity="0.3" filter="url(#filter0_f_39_4268)">
                        <circle cx="247.5" cy="747.5" r="247.5" fill="#FFE500"/>
                    </g>
                    <defs>
                        <filter id="filter0_f_39_4268" x="-500" y="0" width="1495" height="1495"
                                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                            <feGaussianBlur stdDeviation="250" result="effect1_foregroundBlur_39_4267"/>
                        </filter>
                    </defs>
                </svg>
            </div>
        </div>
    );
}

export default ServiceSection;